export const environment = {
  production: true,
  reportBaseUrl: 'https://sql-server-aq.deopti.com/DevxReport',
 // reportBaseUrl: 'http://localhost:5000/DevxReport',
  reportActionUrl: '/WebDocumentViewer/Invoke',
   baseUrl: 'https://sql-server-aq.deopti.com/api/',
  //  baseUrl: 'http://3.109.3.54:8080/api/',
// baseUrl: 'https://dehlawi-ftr.azurewebsites.net/api/',
  secret: '',
version: 'V 5.0.11 (202501221055)'
};
